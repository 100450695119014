import { notice } from 'app/notice'
import { i18n } from 'i18n'
import { makeAutoObservable, observable } from 'mobx'
import { BotEditStore } from 'saas/store/product/bot/BotEditStore'
import { productsConfig, ProductsConfigStore } from 'saas/store/products/ProductsConfigStore'
import { BotProduct } from 'type/product/BotProduct'
import { skipAsync } from 'util/async'
import { updateObject } from 'util/object'

export class BotProductStore {
  readonly company_id: string
  private _json: BotProduct
  private _edit!: BotEditStore

  constructor(company_id: string, json: BotProduct) {
    this.company_id = company_id
    makeAutoObservable<this, '_json'>(this, { _json: observable.ref })
    this._json = json
  }

  get product_id(): string {
    return this._json.product_id
  }

  get json(): BotProduct {
    return this._json
  }

  get busy(): boolean {
    return this.config.busy
  }

  get edit(): BotEditStore {
    return this._edit
  }

  readonly saveSettings = skipAsync(async () => {
    await this.edit.connect()
    if (!this.edit.check()) return

    const { updates } = this.edit
    const json = updateObject(this._json, updates)
    this.update(json)

    await this.save()
    notice.success(i18n('message.Saved'))
  })

  open() {
    this._edit = new BotEditStore(this._json)
  }

  private get config(): ProductsConfigStore {
    return productsConfig.get(this.company_id)
  }

  private update(json: BotProduct) {
    json.updated_at = new Date().toISOString()
    this._json = json
    this._edit.update(json)
  }

  private async save() {
    await this.config.updateProduct(this._json)
  }
}
