import { Button } from 'component/Button'
import { Card } from 'component/Card'
import { Image } from 'component/Image'
import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { Divider } from 'component/Space/Divider'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Text } from 'component/Typo/Text'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { StatusTag } from 'saas/page/product/common/StatusTag/StatusTag'
import { ProductSaasStore } from 'saas/store/product/ProductSaasStore'
import { productsSaas } from 'saas/store/products/ProductsSaasController'
import { ui } from 'store/home/ui/HomeUiStore'
import { Product } from 'type/product/Product'
import { getProductTypeTitle, STAND } from 'type/product/ProductType'
import { formatDateTime, parseDate } from 'util/date'

type Props = {
  product: ProductSaasStore
}

function getProductImage(json: Product): string | undefined {
  if (json.type === STAND) return json.logo || json.icon
  // xxx bot image
  return undefined
}

export const ProductCard = observer(({ product }: Props) => {
  const { small } = ui.window
  const { company_id } = productsSaas.it.config
  const image = getProductImage(product.json)
  const { product_id, type, name, created_at, updated_at } = product.json
  const path = `/company/${company_id}/product/${product_id}`

  return <Card pad={12} width={small ? '100%' : 280} vertical tertiary>
    <Space gap={12} top opposite>
      <Vertical gap={4}>
        <Typo size={13} secondary>
          {getProductTypeTitle(type)}
        </Typo>
        <Space height={64} top>
          <Text size={15} semi rows={3}>
            {name}
          </Text>
        </Space>
      </Vertical>
      {image && <Card width={48} height={48} flex="0 0 auto">
        <Image src={image} />
      </Card>}
    </Space>
    <Gap height={12} />
    <StatusTag product={product} />
    <Gap height={12} />
    <Typo size={11} secondary>{i18n('label.CreatedAt')} {formatDateTime(parseDate(created_at))}</Typo>
    <Gap height={4} />
    <Typo size={11} secondary>{i18n('label.LastUpdate')} {formatDateTime(parseDate(updated_at))}</Typo>
    <Gap height={12} />
    <Divider secondary />
    <Gap height={12} />
    <Link path={path}>
      <Button filled height={40} wide>
        <Icon size={12} name="tune" />
        <Typo>Manage</Typo>
      </Button>
    </Link>
  </Card>
})
